import React, { Children, lazy } from 'react';
import { useRoutes } from 'react-router-dom';

import Loadable from 'ui-component/Loadable';
// project imports
import MainRoutes from './MainRoutes';
import LessonRoutes from './LessonRoutes';
import PlacementRoutes from './PlacementRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import MainLayout from 'layout/MainLayout';

const LandingPage = Loadable(lazy(() => import('views/pages/landing')));

// ===========================|| ROUTING RENDER ||=========================== //

export default function ThemeRoutes() {
    return useRoutes([
        {
            path: '/',
            element: <MainLayout />,
            children: [
                {
                    path: '/',
                    element: <LandingPage />
                }
            ]
        },

        AuthenticationRoutes,
        MainRoutes,
        LessonRoutes,
        PlacementRoutes
    ]);
}
