import { createStore, compose, applyMiddleware } from 'redux';
import { persistStore } from 'redux-persist';
import reducer from './reducer';
import createSagaMiddleware from 'redux-saga';
import watcherSaga from './sagas/rootSaga';

const sagaMiddleware = createSagaMiddleware();

const middleware = [sagaMiddleware];

// ===========================|| REDUX - MAIN STORE ||=========================== //
/* eslint-disable no-underscore-dangle */
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducer, composeEnhancer(applyMiddleware(...middleware)));
/* eslint-enable */
const persister = persistStore(store);

sagaMiddleware.run(watcherSaga);

export { store, persister };
