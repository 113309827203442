import axios from 'axios';
import API_BASE_URL from './config';

const courseService = {
    getCourseList(category) {
        return axios.request({
            method: 'get',
            url: `${API_BASE_URL}api/v1/trainings/catalogue?_format=json&category=${category}`,
            headers: {
                Authorization: 'Basic cmVhZGVyOjZuN25ZQEUzNCQjWQ=='
            }
        });
    }
};

export default courseService;
