import React, { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import LessonReport from 'views/pages/lessons/lesson-report';

// sample page routing
const PlacementTest = Loadable(lazy(() => import('views/pages/placement-test/index')));
const Course = Loadable(lazy(() => import('views/pages/course')));
const Club = Loadable(lazy(() => import('views/pages/club')));
const ClubList = Loadable(lazy(() => import('views/pages/clubs-list')));
const Lesson = Loadable(lazy(() => import('views/pages/lessons')));
const AboutPage = Loadable(lazy(() => import('views/pages/about')));
const Articlepage = Loadable(lazy(() => import('views/pages/article')));
const ProfilePage = Loadable(lazy(() => import('views/pages/profile')));
const Shop = Loadable(lazy(() => import('views/pages/shop')));

// ===========================|| MAIN ROUTING ||=========================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/courses/:courseGroupId',
            element: <Course />
        },
        {
            path: '/clubs/1',
            element: <Club />
        },
        {
            path: '/article/:articleId',
            element: <AboutPage />
        },
        {
            path: '/methods',
            element: <Articlepage articleID="240" key="240" />
        },
        {
            path: '/about-english-focus',
            element: <Articlepage articleID="243" key="243" />
        },
        {
            path: '/terms-and-conditions',
            element: <Articlepage articleID="241" key="241" />
        },
        {
            path: '/privacy-policy',
            element: <Articlepage articleID="242" key="242" />
        },
        {
            path: '/profile',
            element: <ProfilePage />
        },
        {
            path: '/test-store',
            element: <Shop />
        },
        {
            path: '/placement',
            element: <PlacementTest />
        },
        {
            path: '/lesson-report/:lessonId',
            element: <LessonReport />
        }
    ]
};

export default MainRoutes;
