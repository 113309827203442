import axios from 'axios';
import { isArray } from 'lodash';
import API_BASE_URL from './config';

const placementService = {
    getQuestionList(level, excludedArray = []) {
        let excludedQ = '';
        if (isArray(excludedArray)) {
            excludedQ = excludedArray.join();
        }
        return axios.request({
            method: 'GET',
            url: `${API_BASE_URL}api/placement-test/${level}/${excludedQ}?_format=hal_json`
        });
    },

    async setPlacementScore(score, level) {
        const obj = {
            score,
            level
        };
        const id = localStorage.getItem('uid');
        const response = await axios.post(`${API_BASE_URL}api/v1/user/${id}/placement`, obj);
        return response;
    }
};

export default placementService;
