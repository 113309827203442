import axios from 'axios';
import API_BASE_URL from './config';

const API_URL = 'https://admin.englishfocus.com/';

const headers = {
    'X-CSRF-Token': localStorage.getItem('X-CSRF-Token')
    // 'Access-Control-Allow-Origin': '*',
    // 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
};

const AuthService = {
    async getXCSRFToken() {
        const response = await axios.get(`${API_URL}session/token`);
        return response;
    },

    async login(username, password) {
        try {
            const response = await axios.post(`${API_URL}api/v1/token?_format=hal_json`, { username, password, jwt: '' }, { headers });
            return response;
        } catch (error) {
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            }
            return error.response;
        }
    },
    async register(register) {
        const registerObj = {
            first_name: register.firstName,
            last_name: register.lastName,
            country: register.country,
            email: register.email,
            password: register.password
        };
        const response = await axios.post(`${API_URL}api/v1/user`, registerObj, { headers });
        return response;
    },

    getUser(id) {
        return axios.request({
            method: 'GET',
            url: `${API_BASE_URL}api/v1/user/${id}`
        });
    },

    async UpdateUser(user, id) {
        // return axios.request({
        //     method: 'POST',
        //     url: `${API_BASE_URL}api/v1/user/${id}`,
        //     data: user
        // });
        const obj = {
            first_name: user.first_name,
            last_name: user.last_name,
            country: +user.country,
            email: user.email
        };
        const response = await axios.post(`${API_BASE_URL}api/v1/user/${id}`, obj);
        return response;
    },
    async uploadUserPicture(binaryData, name) {
        const data = binaryData;
        const disposition = `file; filename="${name}"`;

        const response = await axios.post(`${API_BASE_URL}file/upload/user/user/user_picture?_format=json`, data, {
            headers: {
                ...headers,
                'Content-Type': 'application/octet-stream',
                'Content-Disposition': disposition
            }
        });
        return response;
    },
    async attachToUser(fid, id) {
        const data = {
            user_picture_fid: fid
        };

        const response = await axios.post(`${API_BASE_URL}api/v1/user/${id}/user_picture`, data, {
            headers
        });

        return response;
    }
};

// Fake url
// https://reqres.in/api/login

export default AuthService;
