import React from 'react';

// material-ui
import { makeStyles, useTheme } from '@material-ui/styles';
import { Container, Grid, Link, Typography } from '@material-ui/core';

// project imports
import { gridSpacing } from 'store/constant';

// assets
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import tiktok from 'assets/images/icons/tiktok-brands.png';

// style constant
const useStyles = makeStyles((theme) => ({
    footer: {
        padding: '35px 0',
        color: '#fff',
        background: theme.palette.secondary.main,
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center'
        }
    },
    footerLink: {
        color: '#fff',
        display: 'inline-flex',
        alignItems: 'center',
        textDecoration: 'none !important',
        opacity: '0.8',
        '& svg': {
            fontsize: '18px',
            marginRight: '8px'
        },
        '&:hover': {
            opacity: '1'
        }
    },
    footerSub: {
        position: 'absolute',
        bottom: 0,
        zIndex: 1300,
        width: '100%',
        padding: '20px 0',
        color: '#fff',
        background: theme.palette.grey[600],
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center'
        }
    }
}));
// ==============================|| LANDING - FOOTER PAGE ||============================== //

const FooterPage = () => {
    const theme = useTheme();
    const classes = useStyles();
    return (
        <>
            <div className={classes.footerSub} id="footer">
                {/* <Container> */}
                <Grid container>
                    <Grid item xs={4} align="left" pl={3} id="social-media">
                        <Link href="https://twitter.com/EnglishFocusEF1" target="_blank" className={classes.footerLink}>
                            <TwitterIcon />
                        </Link>
                        <Link href="https://www.instagram.com/englishfocusef/" target="_blank" className={classes.footerLink}>
                            <InstagramIcon />
                        </Link>
                        <Link href="https://www.facebook.com/EnglishFocusContact/" target="_blank" className={classes.footerLink}>
                            <FacebookIcon />
                        </Link>
                        <Link href="http://tiktok.com/@englishfocusef1" target="_blank" className={classes.footerLink}>
                            <img src={tiktok} alt="tiktok" id="tiktok" />
                        </Link>
                    </Grid>
                    <Grid item xs={8} align="right" pr={3} id="menu-links">
                        <Link href="/terms-and-conditions" underline="none" className={classes.footerLink}>
                            Terms &#38; conditions |
                        </Link>
                        <Link href="/privacy-policy" underline="none" className={classes.footerLink}>
                            &nbsp; Privacy policy
                        </Link>
                    </Grid>
                    <Typography
                        component="div"
                        color="inherit"
                        sx={{
                            display: 'inline-block',
                            paddingRight: '30px',
                            marginLeft: 'auto'
                        }}
                        id="copyright"
                    >
                        &#169; English Focus
                    </Typography>
                </Grid>
                {/* </Container> */}
            </div>
        </>
    );
};

export default FooterPage;
