import axios from 'axios';
import API_BASE_URL from './config';

const courseService = {
    getLandingRequest() {
        return axios.request({
            method: 'get',
            url: `${API_BASE_URL}api/homepage?_format=hal_json`
        });
    }
};

export default courseService;
