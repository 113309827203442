import React from 'react';

// material-ui
import { makeStyles, useTheme } from '@material-ui/styles';
import { Grid, Link, Typography } from '@material-ui/core';

// assets
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';

// style constant
const useStyles = makeStyles((theme) => ({
    footer: {
        padding: '35px 0',
        color: '#fff',
        background: theme.palette.secondary.main,
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center'
        }
    },
    footerLink: {
        color: '#fff',
        display: 'inline-flex',
        alignItems: 'center',
        textDecoration: 'none !important',
        opacity: '0.8',
        '& svg': {
            fontsize: '18px',
            marginRight: '8px'
        },
        '&:hover': {
            opacity: '1'
        }
    },
    footerSub: {
        position: 'absolute',
        bottom: 0,
        zIndex: 1300,
        width: '100%',
        padding: '20px 0',
        color: '#fff',
        background: theme.palette.grey[600],
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center'
        }
    }
}));
// ==============================|| LANDING - FOOTER PAGE ||============================== //

const FooterPage = () => {
    const theme = useTheme();
    const classes = useStyles();
    return (
        <>
            <div className={`lesson-footer ${classes.footerSub}`}>
                <Grid container>
                    <Grid item xs={6} align="left" pl={3}>
                        <Link href="#" target="_blank" className={classes.footerLink}>
                            <TwitterIcon />
                        </Link>
                        <Link href="#" target="_blank" className={classes.footerLink}>
                            <InstagramIcon />
                        </Link>
                        <Link href="#" target="_blank" className={classes.footerLink}>
                            <FacebookIcon />
                        </Link>
                    </Grid>
                    <Grid item xs={6} align="right" pr={3}>
                        <Typography component="div" color="inherit">
                            &#169; English Focus
                        </Typography>
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

export default FooterPage;
