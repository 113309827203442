import React, { lazy } from 'react';
// project imports
import LessonLayout from 'layout/LessonLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import LessonReport from 'views/pages/lessons/lesson-report';

// sample page routing
const PlacementTest = Loadable(lazy(() => import('views/pages/placement-test/index')));
const CourseList = Loadable(lazy(() => import('views/pages/course-list')));
const Course = Loadable(lazy(() => import('views/pages/course')));
const Club = Loadable(lazy(() => import('views/pages/club')));
const ClubList = Loadable(lazy(() => import('views/pages/clubs-list')));
const Lesson = Loadable(lazy(() => import('views/pages/lessons')));
const Assessment = Loadable(lazy(() => import('views/pages/lessons/assessment')));
// const LessonReport = Loadable(lazy(() => import('views/pages/lessons/lesson-report')));

// ===========================|| MAIN ROUTING ||=========================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <LessonLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/lesson/:lessonId/:lessonType',
            element: <Lesson />
        },
        {
            path: '/assessment',
            element: <Assessment />
        }
        // {
        //     path: '/lesson-report/:lessonId',
        //     element: <LessonReport />
        // }
    ]
};

export default MainRoutes;
