import { call, put } from 'redux-saga/effects';
import courseService from 'services/course.service';
import { setCourseList } from 'store/reducers/courseReducer';

export default function* handleGetCoursesList({ category }) {
    try {
        const response = yield call(courseService.getCourseList, category);
        yield put(setCourseList(response.data.items));
    } catch (error) {
        console.log(error);
    }
}
