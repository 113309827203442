import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ADD_PRODUCTS, SNACKBAR_OPEN } from 'store/actions';

// project imports
import useAuth from 'hooks/useAuth';
import { useEffect } from 'react';

// ===========================|| AUTH GUARD ||=========================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }) => {
    const isLoggedIn = useSelector((state) => state.account.isLoggedIn);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const user = useSelector((state) => state.user.userDetails);

    useEffect(() => {
        if (!isLoggedIn) {
            if (location.pathname === '/placement') {
                navigate('register', { replace: true });
            } else {
                navigate('login', { replace: true });
            }

            if (location.pathname === '/courses') {
                dispatch({
                    type: SNACKBAR_OPEN,
                    open: true,
                    message: 'Please log in and take the placement test to find your level',
                    variant: 'alert',
                    alertSeverity: 'success'
                });
            }
        } else if (user.level === '283') {
            if (location.pathname === '/courses') {
                navigate('placement', { replace: true });
                dispatch({
                    type: SNACKBAR_OPEN,
                    open: true,
                    message: 'Please take the placement test to find your level',
                    variant: 'alert',
                    alertSeverity: 'success'
                });
            }
        }
    }, [isLoggedIn, navigate]);

    return children;
};

AuthGuard.propTypes = {
    children: PropTypes.node
};

export default AuthGuard;
