import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// reducer import
import customizationReducer from './reducers/customizationReducer';
import snackbarReducer from './reducers/snackbarReducer';
import cartReducer from './reducers/cartReducer';
import accountReducer from './reducers/accountReducer';
import courseReducer from './reducers/courseReducer';
import placementReducer from './reducers/placementReducer';
import landingReducer from './reducers/landingReducer';
import lessonReducer from './reducers/lessonReducer';
import countryReducer from './reducers/countryReducer';
import userReducer from './reducers/userReducer';
import articleReducer from './reducers/articleReducer';

// ===========================|| COMBINE REDUCER ||=========================== //

const reducer = combineReducers({
    account: persistReducer(
        {
            key: 'account',
            storage,
            keyPrefix: 'ef-'
        },
        accountReducer
    ),
    article: articleReducer,
    courses: courseReducer,
    landingContent: landingReducer,
    placement: placementReducer,
    customization: customizationReducer,
    lesson: lessonReducer,
    snackbar: snackbarReducer,
    country: countryReducer,
    user: persistReducer(
        {
            key: 'user',
            storage,
            keyPrefix: 'ef-'
        },
        userReducer
    )
});

export default reducer;
