import React, { lazy } from 'react';
import Loadable from 'ui-component/Loadable';

// project imports
import MinimalLayout from 'layout/MinimalLayout';

const LoginPage = Loadable(lazy(() => import('views/pages/authentication/login')));
const RegisterPage = Loadable(lazy(() => import('views/pages/authentication/register')));
const PlacementTest = Loadable(lazy(() => import('views/pages/placement-test/index')));

const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/login',
            element: <LoginPage />
        },
        {
            path: '/register',
            element: <RegisterPage />
        }
    ]
};

export default AuthenticationRoutes;
