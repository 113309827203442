import { takeLatest, takeEvery } from 'redux-saga/effects';
import {
    GET_COUNTRY_LIST,
    GET_COURSES,
    GET_COURSE_LIST,
    GET_LANDING_CONTENT,
    GET_QUESTIONS_LIST,
    GET_USER,
    GET_ARTICLE_CONTENT
} from 'store/actions';

import handleGetCountries from './handlers/country';
import handleGetCoursesList from './handlers/courseList';
import handleGetCourses from './handlers/courses';
import handleGetLandingContent from './handlers/landing';
import handleGetQuestionsList from './handlers/questions';
import handleGetArticleContent from './handlers/article';
import handleGetUser from './handlers/user';

export default function* watcherSaga() {
    yield takeLatest(GET_COURSES, handleGetCourses);
    yield takeLatest(GET_LANDING_CONTENT, handleGetLandingContent);
    yield takeLatest(GET_ARTICLE_CONTENT, handleGetArticleContent);
    yield takeLatest(GET_COURSE_LIST, handleGetCoursesList);
    yield takeEvery(GET_QUESTIONS_LIST, handleGetQuestionsList);
    yield takeLatest(GET_COUNTRY_LIST, handleGetCountries);
    yield takeLatest(GET_USER, handleGetUser);
}
