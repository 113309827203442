import {
    NEXT_STEP,
    SET_QUESTIONS_LIST,
    SET_STAGE_STATUS,
    GET_QUESTIONS_LIST,
    SET_ACTIVE_QUESTION,
    SET_STAGE,
    SET_WELCOME_PAGE,
    SET_ANSWER,
    SET_SCORE,
    SET_GROUP
} from '../actions';

const initialState = {
    activeStep: 0,
    stageStatus: false,
    questions9: [],
    questions10: [],
    questions11: [],
    questions12: [],
    questions13: [],
    questions14: [],
    activeQuestions: [],
    stage: 1,
    setWelcome: true,
    score: 0,
    group: 0
};

export const nextStep = (step) => ({
    type: NEXT_STEP,
    payload: { activeStep: step }
});

export const setStageStatus = (status) => ({
    type: SET_STAGE_STATUS,
    payload: { stageStatus: status }
});

export const setStage = (stage) => ({
    type: SET_STAGE,
    payload: { stage }
});

export const getQuestions = (level, excluded) => ({
    type: GET_QUESTIONS_LIST,
    level,
    excluded
});

export const setGroup = (group) => ({
    type: SET_GROUP,
    payload: {
        group
    }
});

export const setAnswer = (value, answer) => ({
    type: SET_ANSWER,
    payload: {
        value,
        answer
    }
});

export const setScore = (score) => ({
    type: SET_SCORE,
    payload: {
        score
    }
});

export const setQuestions = (questions, level) => ({
    type: SET_QUESTIONS_LIST,
    payload: { level, questions }
});

export const setActiveQuestions = (question) => ({
    type: SET_ACTIVE_QUESTION,
    payload: { question }
});

export const setWelcomePage = (set) => ({
    type: SET_WELCOME_PAGE,
    payload: { setWelcome: set }
});

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case NEXT_STEP:
            return { ...state, ...payload };
        case SET_STAGE_STATUS:
            return { ...state, ...payload };
        case SET_QUESTIONS_LIST:
            return { ...state, [`questions${payload.level}`]: payload.questions };
        case SET_ACTIVE_QUESTION:
            return { ...state, activeQuestions: [...state.activeQuestions, payload.question] };
        case SET_STAGE:
            return { ...state, ...payload };
        case SET_WELCOME_PAGE:
            return { ...state, ...payload };
        case SET_ANSWER:
            return { ...state, ...payload };
        case SET_SCORE:
            return { ...state, ...payload };
        case SET_GROUP:
            return { ...state, ...payload };
        default:
            return state;
    }
};
