import React, { lazy } from 'react';
import Loadable from 'ui-component/Loadable';
import MainLayout from 'layout/MainLayout';

// project imports
import MinimalLayout from 'layout/MinimalLayout';

const LoginPage = Loadable(lazy(() => import('views/pages/authentication/login')));
const RegisterPage = Loadable(lazy(() => import('views/pages/authentication/register')));
const PlacementTest = Loadable(lazy(() => import('views/pages/placement-test/index')));
const ClubList = Loadable(lazy(() => import('views/pages/clubs-list')));
const CourseList = Loadable(lazy(() => import('views/pages/course-list')));

const AuthenticationRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        // {
        //     path: '/placement',
        //     element: <PlacementTest />
        // }
        // {
        //     path: '/clubs',
        //     element: <ClubList />
        // }
        {
            path: '/clubs',
            element: <ClubList />
        },
        {
            path: '/courses',
            element: <CourseList />
        }
    ]
};

export default AuthenticationRoutes;
