import { Tabs, Typography, Box, Tab, Chip } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';
import LessonReporSpeakingOne from './speakingone';

// tab content customize
// function TabPanel({ children, value, index, ...other }) {
//     return (
//         <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
//             {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
//         </div>
//     );
// }

// TabPanel.propTypes = {
//     children: PropTypes.node,
//     index: PropTypes.any.isRequired,
//     value: PropTypes.any.isRequired
// };

// function a11yProps(index) {
//     return {
//         id: `simple-tab-${index}`,
//         'aria-controls': `simple-tabpanel-${index}`
//     };
// }

// style constant
const useStyles = makeStyles((theme) => ({
    accountTab: {
        marginBottom: '24px',
        '& a': {
            minHeight: 'auto',
            minWidth: '10px',
            padding: '12px 8px',
            marginRight: '18px',
            color: theme.palette.grey[600],
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center'
        },
        '& a > svg': {
            marginBottom: '0px !important',
            marginRight: '10px'
        }
    },
    badgeSecondary: {
        color: theme.palette.secondary.main,
        background: theme.palette.secondary.light,
        marginLeft: '10px'
    }
}));

const AssessmentTabs = () => {
    const classes = useStyles();

    // const [value, setValue] = React.useState(0);
    // const handleChange = (event, newValue) => {
    //     setValue(newValue);
    // };

    return (
        <>
            {/* <Tabs
                value={value}
                variant="scrollable"
                onChange={handleChange}
                className={classes.accountTab}
                textColor="secondary"
                indicatorColor="secondary"
            >
                <Tab component={RouterLink} to="#" label="Speaking 1" {...a11yProps(0)} />
                <Tab component={RouterLink} to="#" label="Speaking 2" {...a11yProps(1)} />
                <Tab component={RouterLink} to="#" label="Speaking 3" {...a11yProps(2)} />
                <Tab component={RouterLink} to="#" label="Speaking 4" {...a11yProps(3)} />
            </Tabs> */}
            {/* <TabPanel value={value} index={0}>
                <LessonReporSpeakingOne />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <LessonReporSpeakingOne />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <LessonReporSpeakingOne />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <LessonReporSpeakingOne />
            </TabPanel> */}
            <LessonReporSpeakingOne />
        </>
    );
};
export default AssessmentTabs;
