import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { makeStyles } from '@material-ui/styles';
import { useTheme } from '@material-ui/core/styles';
import { Avatar, Chip, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import ListItemButton from '@material-ui/core/ListItemButton';

// project imports
import { MENU_OPEN } from 'store/actions';

// assets
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import Countdown, { zeroPad } from 'react-countdown';
import { setLessonStage } from '../../../../../store/reducers/lessonReducer';

// style constant
const useStyles = makeStyles((theme) => ({
    listIcon: {
        minWidth: '18px',
        marginTop: 'auto',
        marginBottom: 'auto'
    },
    listCustomIconSub: {
        width: '6px',
        height: '6px'
    },
    listCustomIconSubActive: {
        width: '8px',
        height: '8px'
    },
    listItem: {
        marginBottom: '5px',
        alignItems: 'center'
    },
    listItemNoBack: {
        marginBottom: '5px',
        backgroundColor: 'transparent !important',
        paddingTop: '8px',
        paddingBottom: '8px',
        alignItems: 'flex-start',
        display: 'none'
    },
    subMenuCaption: {
        ...theme.typography.subMenuCaption
    },
    timeIndicator: {
        color: '#9e9e9e',
        fontSize: '0.6875rem',
        fontWeight: '500',
        margin: '0'
    }
}));

/* eslint-disable no-restricted-globals */
const NavItem = ({ item, level }) => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const counterRef = useRef();
    const customization = useSelector((state) => state.customization);
    const lessonActivities = useSelector((state) => state.lesson.lessonActivities);
    const lessonStage = useSelector((state) => state.lesson.lessonStage);

    const Icon = item.icon;
    const itemIcon = item.icon ? (
        <Icon stroke={1.5} size="1.3rem" className={classes.listCustomIcon} />
    ) : (
        <FiberManualRecordIcon
            className={
                customization.isOpen.findIndex((id) => id === item.id) > -1 ? classes.listCustomIconSubActive : classes.listCustomIconSub
            }
            fontSize={level > 0 ? 'inherit' : 'default'}
        />
    );

    let itemIconClass = !item.icon ? classes.listIcon : classes.menuIcon;
    itemIconClass = customization.navType === 'nav-dark' ? [itemIconClass, classes.listCustomIcon].join(' ') : itemIconClass;

    let itemTarget = '';
    if (item.target) {
        itemTarget = '_blank';
    }

    let listItemProps = { component: React.forwardRef((props, ref) => <Link {...props} to={item.url} ref={ref} />) };
    if (item.external) {
        listItemProps = { component: 'a', href: item.url };
    }

    const addMins = (mins) => new Date(new Date().getTime() + mins * 60000);

    const handleStop = () => {
        // if (lessonActivities.length > lessonStage) {
        //     dispatch(setLessonStage((lessonStage += 1)));
        // }
    };

    React.useEffect(() => {
        if (!isNaN(lessonStage) && lessonStage === item.id) {
            if (item.time) {
                // counterRef.current.start();
            }
        }
    }, [lessonStage]);

    // active menu item on page load
    React.useEffect(() => {
        const currentIndex = document.location.pathname
            .toString()
            .split('/')
            .findIndex((id) => id === item.id);

        if (currentIndex > -1) {
            dispatch({ type: MENU_OPEN, id: item.id });
        }

        // eslint-disable-next-line
    }, []);

    // console.log('lessonStage', lessonStage);
    // console.log('item.id', item.id);

    return (
        <ListItemButton
            {...listItemProps}
            disabled={item.disabled}
            className={level > 1 ? classes.listItemNoBack : classes.listItem}
            sx={{ borderRadius: `${customization.borderRadius}px` }}
            selected={lessonStage === item.id}
            target={itemTarget}
            style={{ paddingLeft: `${level * 23}px` }}
        >
            <ListItemIcon className={itemIconClass}>{itemIcon}</ListItemIcon>
            <ListItemText
                primary={
                    <Typography variant={customization.isOpen.findIndex((id) => id === item.id) > -1 ? 'h5' : 'body1'}>
                        {item.title}
                    </Typography>
                }
                secondary={
                    item.time !== '' &&
                    item.time !== 0 && (
                        <Typography variant="span" color="custom.gray" className={classes.timeIndicator}>
                            {isNaN(lessonStage) || lessonStage - 1 < item.id ? [lessonStage === item.id ? 'In progress' : ''] : 'Completed'}
                        </Typography>
                    )
                }
            />

            {item.chip && (
                <Chip
                    color={item.chip.color}
                    variant={item.chip.variant}
                    size={item.chip.size}
                    label={item.chip.label}
                    avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
                />
            )}
        </ListItemButton>
    );
};

NavItem.propTypes = {
    item: PropTypes.object,
    level: PropTypes.number
};

export default NavItem;
