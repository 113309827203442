import { GET_LANDING_CONTENT, SET_LANDING_CONTENT } from '../actions';

const initialState = {
    data: []
};

export const setLandingContent = (content) => ({
    type: SET_LANDING_CONTENT,
    payload: { data: content }
});

export const getLandingContent = () => ({
    type: GET_LANDING_CONTENT
});

const courseReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_LANDING_CONTENT:
            return { ...state, ...payload };
        default:
            return state;
    }
};

export default courseReducer;
