import React from 'react';

// material-ui
import { Typography } from '@material-ui/core';

// project imports
import NavGroup from './NavGroup';
import menuItems from 'menu-items';
import { useLocation } from 'react-router';
import menuItemsLessons from 'menu-items-lessons';
import { useSelector } from 'react-redux';

// ===========================|| SIDEBAR MENU LIST ||=========================== //

const MenuList = () => {
    const location = useLocation();
    const lessonActivities = useSelector((state) => state.lesson.lessonActivities);
    let menuList = [];
    menuList = menuItems;

    if (lessonActivities) {
        const activityMenu = lessonActivities.map((activity, i) => ({
            id: i + 1,
            title: activity.name,
            type: 'lesson-stage',
            url: window.location.pathname,
            time: activity.field_stage_duration / 60,
            breadcrumbs: false
        }));

        menuItemsLessons.items[1].children = activityMenu;
        menuList = menuItemsLessons;
    }

    const navItems = menuList.items.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return navItems;
};

export default MenuList;
