import { GET_ARTICLE_CONTENT, SET_ARTICLE_CONTENT } from '../actions';

const initialState = {
    content: []
};

export const setArticleContent = (content) => ({
    type: SET_ARTICLE_CONTENT,
    payload: { content }
});

export const getArticleContent = (id) => ({
    type: GET_ARTICLE_CONTENT,
    id
});

const courseReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_ARTICLE_CONTENT:
            return { ...state, ...payload };
        default:
            return state;
    }
};

export default courseReducer;
