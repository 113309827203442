import { call, put } from 'redux-saga/effects';
import landingService from 'services/landing.service';
import { setLandingContent } from 'store/reducers/landingReducer';

export default function* handleGetLandingContent(action) {
    try {
        const response = yield call(landingService.getLandingRequest);
        yield put(setLandingContent(response.data[0]));
    } catch (err) {
        console.log(err);
    }
}
