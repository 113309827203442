import { SET_PEER_STAGE, SET_LESSON_STAGE, SET_USERNAME, SET_LESSON_ACTIVITIES, SET_PAIRING_DATA } from '../actions';

const initialState = {
    lessonStage: 'waiting-room',
    // lessonStage: 2,
    peerStage: 1,
    usernameAlreadySelected: false,
    user: Object,
    pairingData: {
        pairing: {
            partner_id: null
        },
        partner: {
            first_name: 'Partner',
            last_name: '',
            email: ''
        }
    }
};

export const setLessonStage = (lessonStage) => ({
    type: SET_LESSON_STAGE,
    payload: { lessonStage }
});

export const setPairingData = (pairingData) => ({
    type: SET_PAIRING_DATA,
    payload: { pairingData }
});

export const setPeerStage = (peerStage) => ({
    type: SET_PEER_STAGE,
    payload: { peerStage }
});

export const setUserName = (usernameAlreadySelected) => ({
    type: SET_USERNAME,
    payload: { usernameAlreadySelected }
});

export const setLessonActivities = (lessonActivities) => ({
    type: SET_LESSON_ACTIVITIES,
    payload: { lessonActivities }
});

const lessonReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_PAIRING_DATA:
            return { ...state, ...payload };
        case SET_LESSON_ACTIVITIES:
            return { ...state, ...payload };
        case SET_LESSON_STAGE:
            return { ...state, ...payload };
        case SET_PEER_STAGE:
            return { ...state, ...payload };
        case SET_USERNAME:
            return { ...state, ...payload };
        default:
            return state;
    }
};

export default lessonReducer;
