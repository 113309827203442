import { GET_COURSES, GET_COURSE_LIST, SET_COURSES, SET_COURSE_LIST } from '../actions';

const initialState = {
    data: [],
    courseListData: []
};

export const setCourses = (courses) => ({
    type: SET_COURSES,
    payload: { data: courses }
});

export const getCourses = () => ({
    type: GET_COURSES
});

export const setCourseList = (list) => ({
    type: SET_COURSE_LIST,
    payload: { courseListData: list }
});

export const getCourseList = (category) => ({
    type: GET_COURSE_LIST,
    category
});

const courseReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_COURSES:
            return { ...state, ...payload };
        case SET_COURSE_LIST:
            return { ...state, ...payload };
        default:
            return state;
    }
};

export default courseReducer;
