const other = {
    id: 'sample-docs-roadmap',
    type: 'group',
    children: [
        {
            id: 'home',
            title: 'Home',
            type: 'item',
            url: '/',
            // icon: icons.IconBrandChrome,
            caption: '',
            breadcrumbs: false
        },
        {
            id: 'about 1',
            title: 'Article Heading',
            type: 'item',
            url: '/about',
            caption: 'Jack Radford | 12/03/2021',
            breadcrumbs: false
        },
        {
            id: 'about 2',
            title: 'Article Heading',
            type: 'item',
            url: '/about',
            caption: 'Jack Radford | 12/03/2021',
            breadcrumbs: false
        }
    ]
};
export default other;
