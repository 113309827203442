const commons = {
    id: 'lesson-page-common',
    type: 'group',
    children: [
        // {
        //     id: 'home',
        //     title: 'Home',
        //     type: 'item',
        //     url: '/',
        //     time: '',
        //     breadcrumbs: false
        // },
        {
            id: 'waiting-room',
            title: 'Waiting room',
            type: 'lesson-stage',
            url: window.location.pathname,
            time: '',
            breadcrumbs: false
        }
    ]
};

export const activities = {
    id: 'lesson-page-activities',
    type: 'group',
    children: []
};
export default commons;
