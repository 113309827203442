import React from 'react';
import { useSelector } from 'react-redux';

import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline, StyledEngineProvider } from '@material-ui/core';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
// import RTLLayout from 'ui-component/RTLLayout';
import Snackbar from 'ui-component/extended/Snackbar';

// auth provider
import { FirebaseProvider } from 'contexts/FirebaseContext';
import AuthService from 'services/auth.services';
// import {JWTProvider} from 'contexts/JWTContext';
// import {Auth0Provider} from 'contexts/Auth0Context';

import TagManager from 'react-gtm-module';

const tagManagerArgs = {
    gtmId: 'G-4XHZ2TR6YF'
};

TagManager.initialize(tagManagerArgs);

// ===========================|| APP ||=========================== //

const App = () => {
    const customization = useSelector((state) => state.customization);
    //* *Get X-CSRF-Token */
    AuthService.getXCSRFToken().then((res) => {
        const xcsrfToken = res.data;
        localStorage.setItem('X-CSRF-Token', xcsrfToken);
    });
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                {/* RTL layout */}
                {/* <RTLLayout> */}
                <Locales>
                    <NavigationScroll>
                        <FirebaseProvider>
                            <Routes />
                            <Snackbar />
                        </FirebaseProvider>
                    </NavigationScroll>
                </Locales>
                {/* </RTLLayout> */}
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
