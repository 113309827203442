import { call, put } from 'redux-saga/effects';
import axios from 'axios';
import API_BASE_URL from './config';
import { setArticleContent } from 'store/reducers/articleReducer';

const getArticleRequest = (id) =>
    axios.request({
        method: 'get',
        url: `${API_BASE_URL}api/v1/articles/${id}?_format=hal_json&t=1`
    });

export default function* handleGetArticleContent({ id }) {
    try {
        const response = yield call(getArticleRequest, id);
        yield put(setArticleContent(response.data[0]));
    } catch (err) {
        console.log('English Focus:', err);
    }
}
