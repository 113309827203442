import { GET_COUNTRY_LIST, SET_COUNTRY_LIST } from 'store/actions';

const initialState = {
    countryList: []
};

export const getCountryList = () => ({
    type: GET_COUNTRY_LIST
});

export const setCountryList = (countryList) => ({
    type: SET_COUNTRY_LIST,
    payload: { countryList }
});
const countryReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_COUNTRY_LIST:
            return { ...state, ...payload };
        default:
            return state;
    }
};

export default countryReducer;
