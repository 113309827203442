import React from 'react';
import { useLocation } from 'react-router-dom';

// material-ui
import { Typography } from '@material-ui/core';

// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import menuItemsAbout from 'menu-items-about';
import courseItems from 'menu-items-courses';
import clubItems from 'menu-items-clubs';

// ===========================|| SIDEBAR MENU LIST ||=========================== //

const MenuList = () => {
    const location = useLocation();
    let menuList = [];
    menuList = menuItem;
    // if (location.pathname !== '/about') {
    //     menuItem.items = menuList.filter((item) => item.children.splice(1));
    // }
    if (location.pathname === '/about') {
        menuList = menuItemsAbout;
    } else if (location.pathname.includes('/courses')) {
        menuList = courseItems;
    } else if (location.pathname.includes('/clubs')) {
        menuList = clubItems;
    } else {
        menuList = clubItems;
    }
    const navItems = menuList.items.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return navItems;
};

export default MenuList;
