import { call, put } from 'redux-saga/effects';
import courseService from 'services/course.service';
import { setCourses } from 'store/reducers/courseReducer';

export default function* handleGetCourses(action) {
    try {
        const response = yield call(courseService.getCoursesRequest);
        yield put(setCourses(response.data));
    } catch (err) {
        console.log(err);
    }
}
