import { Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { gridSpacing } from 'store/constant';
import LessonReportTabs from './tab';
import Fade from 'react-reveal/Fade';

const useStyles = makeStyles((theme) => ({
    sectionWhite: {
        paddingBottom: '32px',
        backgroundColor: 'white',
        borderRadius: '12px'
    }
}));

const LessonReportPage = () => {
    const classes = useStyles();

    return (
        <>
            <Fade bottom>
                <Grid item xs={12} md={12} pt={4} pb={4} className="full-width-mobile end-of-lesson-test">
                    <div className={classes.sectionWhite}>
                        <Container>
                            <Grid container spacing={gridSpacing} sx={{ px: 2 }}>
                                <LessonReportTabs />
                            </Grid>
                        </Container>
                    </div>
                </Grid>
            </Fade>
        </>
    );
};
export default LessonReportPage;
