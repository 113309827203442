import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

// material-ui
import { makeStyles } from '@material-ui/styles';
import { Avatar, Box, Button, ButtonBase, Grid, IconButton, Stack, Typography } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import HourglassBottomIcon from '@material-ui/icons/HourglassBottom';

// project imports
import LogoSection from '../LogoSection';
import MobileSection from './MobileSection';

// assets
import { useDispatch, useSelector } from 'react-redux';
import Countdown, { zeroPad, calcTimeDelta, formatTimeDelta } from 'react-countdown';

// style constant
const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1
    },
    headerAvatar: {
        ...theme.typography.commonAvatar,
        ...theme.typography.mediumAvatar,
        transition: 'all .2s ease-in-out',
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
        color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
        '&:hover': {
            background: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
            color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.light
        }
    },
    boxContainer: {
        width: '228px',
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            width: 'auto'
        }
    }
}));

// ===========================|| MAIN NAVBAR / HEADER ||=========================== //

const Header = ({ handleLeftDrawerToggle }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const isLoggedIn = useSelector((state) => state.account.isLoggedIn);
    const lessonStage = useSelector((state) => state.lesson.lessonStage);
    const lessonActivities = useSelector((state) => state.lesson.lessonActivities);
    const [showCurrentActivity, setCurrentActivity] = useState('');
    const [fieldStageDuration, setfieldStageDuration] = useState();

    // console.log(lessonActivities);

    return (
        <>
            {/* logo & toggler button */}
            <div className={classes.boxContainer}>
                {/* <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
                    <Avatar variant="rounded" className={classes.headerAvatar} onClick={handleLeftDrawerToggle} color="inherit">
                        <IconMenu2 stroke={1.5} size="1.3rem" />
                    </Avatar>
                </ButtonBase> */}
                <IconButton onClick={handleLeftDrawerToggle} edge="start" color="inherit" aria-label="menu" sx={{ margin: '15px 0' }}>
                    <MenuIcon />
                </IconButton>

                <Box component="span" sx={{ display: { xs: 'none', md: 'block' } }}>
                    <LogoSection />
                </Box>
            </div>

            {/* header search */}
            <Box
                sx={{
                    display: 'flex',
                    flexGrow: 1,
                    justifyContent: 'space-between',
                    alignContent: 'space-between',
                    bgcolor: 'background.paper'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexGrow: 1,
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mx: 3,
                        bgcolor: 'background.paper'
                    }}
                >
                    <Typography
                        variant="h3"
                        color="custom.gray"
                        sx={{
                            fontSize: { xs: '16px', md: '20px' },
                            display: { xs: 'block', md: 'none' }
                        }}
                    >
                        Welcome to the course
                    </Typography>
                    <Typography
                        variant="h3"
                        color="custom.gray"
                        sx={{
                            fontSize: { xs: '16px', md: '20px' },
                            display: { xs: 'none', md: 'block' },
                            fontWeight: '400'
                        }}
                    >
                        Learn English by speaking English
                    </Typography>
                    {/* <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography
                            variant="h3"
                            color="custom.gray"
                            sx={{
                                fontSize: { xs: '16px', md: '20px' },
                                display: { xs: 'none', md: 'block' },
                                fontWeight: '400'
                            }}
                        >
                            Lesson starts in&nbsp;
                        </Typography>
                        <HourglassBottomIcon
                            sx={{
                                fontSize: { xs: '16px', md: '20px' },
                                display: { xs: 'none', md: 'block' },
                                fontWeight: '400'
                            }}
                        />
                        <Countdown
                            date={Date.now() + 1000000}
                            sx={{
                                display: 'inline'
                            }}
                            renderer={({ hours, minutes, seconds }) => (
                                <>
                                    <Typography
                                        variant="h3"
                                        color="custom.gray"
                                        sx={{
                                            fontSize: { xs: '16px', md: '20px' },
                                            display: { xs: 'none', md: 'block' },
                                            fontWeight: '400'
                                        }}
                                    >
                                        &nbsp;{zeroPad(minutes)}:{zeroPad(seconds)}
                                    </Typography>
                                </>
                            )}
                        />
                    </Box> */}
                </Box>

                <Stack direction="row" sx={{ display: { xs: 'none', sm: 'block' } }} spacing={2}>
                    {!isLoggedIn === true && (
                        <Button variant="contained" color="primary" component={RouterLink} to="/login">
                            Log in
                        </Button>
                    )}

                    {!isLoggedIn === true && (
                        <Button variant="contained" color="primary" component={RouterLink} to="/register">
                            Sign up
                        </Button>
                    )}

                    {isLoggedIn === true && (
                        <>
                            <Button variant="contained" color="primary" component={RouterLink} to="/profile">
                                Profile
                            </Button>
                            <Button variant="contained" color="primary" onClick={() => dispatch({ type: 'LOGOUT' })}>
                                Log out
                            </Button>
                        </>
                    )}
                </Stack>
            </Box>
        </>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
