/* eslint-disable */
import { Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';

// style constant
const useStyles = makeStyles((theme) => ({
    defualtText: {
        fontSize: { xs: '1rem', md: '1.125rem' },
        fontWeight: '400',
        lineHeight: { xs: '24px', md: '32px' }
    },
    mainlessonwrap: {
        padding: 0
    }
}));

const score = {
    paddingRight: '5px'
};

const selectedScore = {
    paddingRight: '5px',
    fontWeight: '700',
    color: '#00acb8'
};

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function Speakingone() {
    const classes = useStyles();
    const params = useParams();
    const user = useSelector((state) => state.user.userDetails);

    const agent = `{"mbox": "mailto:${user.email}"}`;

    const [completionDate, setCompletionDate] = useState(null);

    const getAgent = (mail) => `{"mbox": "mailto:${mail}"}`;

    useEffect(() => {
        if (user.completed_lesson_data !== null) {
            const completionData = user.completed_lesson_data.filter((row) => params.lessonId === row.lessonId);
            if (completionData.length > 0) {
                const dt = new Date(completionData[0].date);
                const dateStr = dt.toLocaleDateString();
                setCompletionDate(dateStr);
            }
        }
    }, [user]);

    // student's activity
    const myActivityStageOne = `${window.location.origin}/lesson/${params.lessonId}/1`;
    const myActivityStageTwo = `${window.location.origin}/lesson/${params.lessonId}/2`;
    const myActivityStageThree = `${window.location.origin}/lesson/${params.lessonId}/3`;

    const endOfLessonActivity = `${window.location.origin}/lesson/${params.lessonId}`;

    // partner's activity
    const partnerActivityStageOne = `${window.location.origin}/lesson/${params.lessonId}/1/partner`;
    const partnerActivityStageTwo = `${window.location.origin}/lesson/${params.lessonId}/2/partner`;
    const partnerActivityStageThree = `${window.location.origin}/lesson/${params.lessonId}/3/partner`;
    // student's statements
    const myResultAssessOne = window.GetStatements(agent, myActivityStageOne);
    const myResultAssessTwo = window.GetStatements(agent, myActivityStageTwo);
    const myResultAssessThree = window.GetStatements(agent, myActivityStageThree);
    const endOfLessonStatements = window.GetStatements(agent, endOfLessonActivity);

    let partnerOneEmail = '';
    let partnerTwoEmail = '';
    let partnerThreeEmail = '';

    // partner's statements
    const partnerResultAssessOne = window.GetStatements(agent, partnerActivityStageOne);
    const partnerResultAssessTwo = window.GetStatements(agent, partnerActivityStageTwo);
    const partnerResultAssessThree = window.GetStatements(agent, partnerActivityStageThree);

    // convert statements into an array.
    const myStatementsAssessOne = Array.from(myResultAssessOne.statements).slice(-4);
    const myStatementsAssessTwo = Array.from(myResultAssessTwo.statements).slice(-4);
    const myStatementsAssessThree = Array.from(myResultAssessThree.statements).slice(-4);

    const endOfLessonArr = Array.from(endOfLessonStatements.statements).slice(-1);

    // let submittedDate = null;
    // if (endOfLessonArr && !completionData) {
    //     if (endOfLessonArr[0]) {
    //         const endOfLessonArrSbtdate = new Date(endOfLessonArr[0].stored);
    //         submittedDate = endOfLessonArrSbtdate.toLocaleDateString();
    //     }
    // } else {
    //     submittedDate = completionData.date.toLocaleDateString();
    // }

    const partnerStatementsAssessOne = Array.from(partnerResultAssessOne.statements).slice(-4);
    const partnerStatementsAssessTwo = Array.from(partnerResultAssessTwo.statements).slice(-4);
    const partnerStatementsAssessThree = Array.from(partnerResultAssessThree.statements).slice(-4);

    const NOT_SUPPLIED = 'n/s';

    // student's variables initilization.
    let myVocabularyAssessOne = NOT_SUPPLIED;
    let myVocabularyAssessTwo = NOT_SUPPLIED;
    let myVocabularyAssessThree = NOT_SUPPLIED;
    let myFluencyAssessOne = NOT_SUPPLIED;
    let myFluencyAssessTwo = NOT_SUPPLIED;
    let myFluencyAssessThree = NOT_SUPPLIED;
    let myPronunciationAssessOne = NOT_SUPPLIED;
    let myPronunciationAssessTwo = NOT_SUPPLIED;
    let myPronunciationAssessThree = NOT_SUPPLIED;
    let myGrammarAssessOne = NOT_SUPPLIED;
    let myGrammarAssessTwo = NOT_SUPPLIED;
    let myGrammarAssessThree = NOT_SUPPLIED;

    let userEndOfLessonScore = NOT_SUPPLIED;

    // partner's variables initilization.
    let partnerNameAssessOne = 'Peter parker peter parker';
    let partnerNameAssessTwo = 'Partner';
    let partnerNameAssessThree = 'Partner';
    let partnerVocabularyAssessOne = NOT_SUPPLIED;
    let partnerVocabularyAssessTwo = NOT_SUPPLIED;
    let partnerVocabularyAssessThree = NOT_SUPPLIED;
    let partnerFluencyAssessOne = NOT_SUPPLIED;
    let partnerFluencyAssessTwo = NOT_SUPPLIED;
    let partnerFluencyAssessThree = NOT_SUPPLIED;
    let partnerPronunciationAssessOne = NOT_SUPPLIED;
    let partnerPronunciationAssessTwo = NOT_SUPPLIED;
    let partnerPronunciationAssessThree = NOT_SUPPLIED;
    let partnerGrammarAssessOne = NOT_SUPPLIED;
    let partnerGrammarAssessTwo = NOT_SUPPLIED;
    let partnerGrammarAssessThree = NOT_SUPPLIED;

    // assigning student's results
    if (myStatementsAssessOne.length > 0) {
        myStatementsAssessOne.forEach((statement) => {
            switch (statement.object.definition.name['en-US']) {
                case 'Fluency test':
                    myFluencyAssessOne = statement.result.score.scaled * 10;
                    break;
                case 'Pronunciation test':
                    myPronunciationAssessOne = statement.result.score.scaled * 10;
                    break;
                case 'Grammar test':
                    myGrammarAssessOne = statement.result.score.scaled * 10;
                    break;
                case 'Vocabulary test':
                    myVocabularyAssessOne = statement.result.score.scaled * 10;
                    break;
                default:
                    break;
            }
        });
    }

    if (myStatementsAssessTwo.length > 0) {
        myStatementsAssessTwo.forEach((statement) => {
            switch (statement.object.definition.name['en-US']) {
                case 'Fluency test':
                    myFluencyAssessTwo = statement.result.score.scaled * 10;
                    break;
                case 'Pronunciation test':
                    myPronunciationAssessTwo = statement.result.score.scaled * 10;
                    break;
                case 'Grammar test':
                    myGrammarAssessTwo = statement.result.score.scaled * 10;
                    break;
                case 'Vocabulary test':
                    myVocabularyAssessTwo = statement.result.score.scaled * 10;
                    break;
                default:
                    break;
            }
        });
    }

    if (myStatementsAssessThree.length > 0) {
        myStatementsAssessThree.forEach((statement) => {
            switch (statement.object.definition.name['en-US']) {
                case 'Fluency test':
                    myFluencyAssessThree = statement.result.score.scaled * 10;
                    break;
                case 'Pronunciation test':
                    myPronunciationAssessThree = statement.result.score.scaled * 10;
                    break;
                case 'Grammar test':
                    myGrammarAssessThree = statement.result.score.scaled * 10;
                    break;
                case 'Vocabulary test':
                    myVocabularyAssessThree = statement.result.score.scaled * 10;
                    break;
                default:
                    break;
            }
        });
    }

    if (endOfLessonArr.length > 0) {
        endOfLessonArr.forEach((statement) => {
            userEndOfLessonScore = statement.result.score.scaled * 10;
        });
    }

    // getting parner emails
    if (partnerStatementsAssessOne.length > 0) {
        partnerStatementsAssessOne.forEach((statement) => {
            partnerNameAssessOne =
                typeof statement.object.definition.description !== 'undefined'
                    ? statement.object.definition.description['en-US']
                    : 'partner';

            partnerOneEmail =
                typeof statement.object.definition.description !== 'undefined' ? statement.object.definition.description['en-GB'] : '';
        });
    }

    if (partnerStatementsAssessTwo.length > 0) {
        partnerStatementsAssessTwo.forEach((statement) => {
            partnerNameAssessTwo =
                typeof statement.object.definition.description !== 'undefined'
                    ? statement.object.definition.description['en-US']
                    : 'partner';

            partnerTwoEmail =
                typeof statement.object.definition.description !== 'undefined' ? statement.object.definition.description['en-GB'] : '';
        });
    }

    if (partnerStatementsAssessThree.length > 0) {
        partnerStatementsAssessThree.forEach((statement) => {
            partnerNameAssessThree =
                typeof statement.object.definition.description !== 'undefined'
                    ? statement.object.definition.description['en-US']
                    : 'partner';

            partnerThreeEmail =
                typeof statement.object.definition.description !== 'undefined' ? statement.object.definition.description['en-GB'] : '';
        });
    }

    // partner's statements
    const defPartnerReverse = { statements: [] };
    const partnerResultAssessOneReverse =
        partnerOneEmail !== '' ? window.GetStatements(getAgent(partnerOneEmail), partnerActivityStageOne) : defPartnerReverse;
    const partnerResultAssessTwoReverse =
        partnerTwoEmail !== '' ? window.GetStatements(getAgent(partnerTwoEmail), partnerActivityStageTwo) : defPartnerReverse;
    const partnerResultAssessThreeReverse =
        partnerThreeEmail !== '' ? window.GetStatements(getAgent(partnerThreeEmail), partnerActivityStageThree) : defPartnerReverse;

    const partnerResultAssessOneReverseStatement = Array.from(partnerResultAssessOneReverse.statements).slice(-4);
    const partnerResultAssessTwoReverseStatement = Array.from(partnerResultAssessTwoReverse.statements).slice(-4);
    const partnerResultAssessThreeReverseStatement = Array.from(partnerResultAssessThreeReverse.statements).slice(-4);

    if (partnerResultAssessOneReverseStatement.length > 0) {
        partnerResultAssessOneReverseStatement.forEach((statement) => {
            switch (statement.object.definition.name['en-US']) {
                case 'Fluency test':
                    partnerFluencyAssessOne = statement.result.score.scaled * 10;
                    break;
                case 'Pronunciation test':
                    partnerPronunciationAssessOne = statement.result.score.scaled * 10;
                    break;
                case 'Grammar test':
                    partnerGrammarAssessOne = statement.result.score.scaled * 10;
                    break;
                case 'Vocabulary test':
                    partnerVocabularyAssessOne = statement.result.score.scaled * 10;
                    break;
                default:
                    break;
            }
        });
    }

    if (partnerResultAssessTwoReverseStatement.length > 0) {
        partnerResultAssessTwoReverseStatement.forEach((statement) => {
            switch (statement.object.definition.name['en-US']) {
                case 'Fluency test':
                    partnerFluencyAssessTwo = statement.result.score.scaled * 10;
                    break;
                case 'Pronunciation test':
                    partnerPronunciationAssessTwo = statement.result.score.scaled * 10;
                    break;
                case 'Grammar test':
                    partnerGrammarAssessTwo = statement.result.score.scaled * 10;
                    break;
                case 'Vocabulary test':
                    partnerVocabularyAssessTwo = statement.result.score.scaled * 10;
                    break;
                default:
                    break;
            }
        });
    }

    if (partnerResultAssessThreeReverseStatement.length > 0) {
        partnerResultAssessThreeReverseStatement.forEach((statement) => {
            switch (statement.object.definition.name['en-US']) {
                case 'Fluency test':
                    partnerFluencyAssessThree = statement.result.score.scaled * 10;
                    break;
                case 'Pronunciation test':
                    partnerPronunciationAssessThree = statement.result.score.scaled * 10;
                    break;
                case 'Grammar test':
                    partnerGrammarAssessThree = statement.result.score.scaled * 10;
                    break;
                case 'Vocabulary test':
                    partnerVocabularyAssessThree = statement.result.score.scaled * 10;
                    break;
                default:
                    break;
            }
        });
    }

    // Method to round off to nearest 2 decimal places
    function roundToTwo(num) {
        return +`${Math.round(`${num}e+2`)}e-2`;
    }

    const getAverage = (marksArr = []) => {
        let nums = 0;
        const total = marksArr.reduce((pre, cur) => {
            if (!isNaN(cur)) {
                nums++;
                return pre + cur;
            }
            return pre;
        }, 0);

        if (nums === 0) {
            nums = 1;
        }

        const avg = total / nums;
        return roundToTwo(avg);
    };

    return (
        <>
            <Grid item xs={6} md={6} className="main-header left">
                <Typography variant="subtitle1" sx={{ color: '#00acb8' }}>
                    SELF AND PARTNER ASSESSMENT
                </Typography>
            </Grid>
            <Grid item xs={6} md={6} sx={{ color: '#616161' }} className="main-header right">
                <Typography variant="subtitle1" sx={{ textAlign: 'right' }}>
                    {completionDate && <>Lesson complete: {completionDate}</>}
                </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
                <Container className={classes.mainlessonwrap}>
                    <Grid container className="leesonReportContainer">
                        <Grid item xs={12}>
                            <Grid container className="row main-titles">
                                <Grid item xs={2} md={2} className="main-t">
                                    <Typography sx={{ pb: 2 }}>SPEAKING</Typography>
                                </Grid>
                                <Grid item xs={3} md={3} className="main-t">
                                    <Typography sx={{ pb: 2 }}>ASSESSMENT 1</Typography>
                                </Grid>
                                <Grid item xs={3} md={3} className="main-t">
                                    <Typography sx={{ pb: 2 }}>ASSESSMENT 2</Typography>
                                </Grid>
                                <Grid item xs={3} md={3} className="main-t">
                                    <Typography sx={{ pb: 2 }}>ASSESSMENT 3</Typography>
                                </Grid>
                                <Grid item xs={1} md={1} className="main-t">
                                    <Typography sx={{ pb: 2 }}> </Typography>
                                </Grid>
                            </Grid>
                            <Grid container className="row main">
                                <Grid item xs={2} md={2} className="title">
                                    <Typography sx={{ pb: 2 }}>VOCABULARY</Typography>
                                </Grid>
                                <Grid item xs={3} md={3} className="scoreItem">
                                    <Grid container>
                                        <Typography sx={{ pb: 1 }} className="mobile-main-title">
                                            ASSESSMENT 1
                                        </Typography>
                                        <Grid item xs={6} className="item you">
                                            <Typography noWrap className="user">
                                                You
                                            </Typography>
                                            <Typography className="score">{myVocabularyAssessOne}</Typography>
                                        </Grid>
                                        <Grid item xs={6} className="item partner">
                                            <Typography noWrap className="user">
                                                {partnerNameAssessOne}
                                            </Typography>
                                            <Typography className="score">{partnerVocabularyAssessOne}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={3} md={3} className="scoreItem">
                                    <Grid container>
                                        <Typography sx={{ pb: 1 }} className="mobile-main-title">
                                            ASSESSMENT 2
                                        </Typography>
                                        <Grid item xs={6} className="item you">
                                            <Typography noWrap className="user">
                                                You
                                            </Typography>
                                            <Typography className="score">{myVocabularyAssessTwo}</Typography>
                                        </Grid>
                                        <Grid item xs={6} className="item partner">
                                            <Typography noWrap className="user">
                                                {partnerNameAssessTwo}
                                            </Typography>
                                            <Typography className="score">{partnerVocabularyAssessTwo}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={3} md={3} className="scoreItem">
                                    <Grid container>
                                        <Typography sx={{ pb: 1 }} className="mobile-main-title">
                                            ASSESSMENT 3
                                        </Typography>
                                        <Grid container>
                                            <Grid item xs={6} className="item you">
                                                <Typography noWrap className="user">
                                                    You
                                                </Typography>
                                                <Typography className="score">{myVocabularyAssessThree}</Typography>
                                            </Grid>
                                            <Grid item xs={6} className="item partner">
                                                <Typography noWrap className="user">
                                                    {partnerNameAssessThree}
                                                </Typography>
                                                <Typography className="score">{partnerVocabularyAssessThree}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={1} md={1} className="scoreItem ave">
                                    <Grid container>
                                        <Grid item xs={12} className="item avarage">
                                            <Typography noWrap className="user">
                                                Average
                                            </Typography>
                                            <Typography className="score">
                                                {getAverage([
                                                    myVocabularyAssessOne,
                                                    myVocabularyAssessTwo,
                                                    myVocabularyAssessThree,
                                                    partnerVocabularyAssessOne,
                                                    partnerVocabularyAssessTwo,
                                                    partnerVocabularyAssessThree
                                                ])}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container className="row main">
                                <Grid item xs={2} md={2} className="title">
                                    <Typography sx={{ pb: 2 }}>GRAMMAR</Typography>
                                </Grid>
                                <Grid item xs={3} md={3} className="scoreItem">
                                    <Grid container>
                                        <Typography sx={{ pb: 1 }} className="mobile-main-title">
                                            ASSESSMENT 1
                                        </Typography>
                                        <Grid item xs={6} className="item you">
                                            <Typography noWrap className="user">
                                                You
                                            </Typography>
                                            <Typography className="score">{myGrammarAssessOne}</Typography>
                                        </Grid>
                                        <Grid item xs={6} className="item partner">
                                            <Typography noWrap className="user">
                                                {partnerNameAssessOne}
                                            </Typography>
                                            <Typography className="score">{partnerGrammarAssessOne}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={3} md={3} className="scoreItem">
                                    <Grid container>
                                        <Typography sx={{ pb: 1 }} className="mobile-main-title">
                                            ASSESSMENT 2
                                        </Typography>
                                        <Grid item xs={6} className="item you">
                                            <Typography noWrap className="user">
                                                You
                                            </Typography>
                                            <Typography className="score">{myGrammarAssessTwo}</Typography>
                                        </Grid>
                                        <Grid item xs={6} className="item partner">
                                            <Typography noWrap className="user">
                                                {partnerNameAssessTwo}
                                            </Typography>
                                            <Typography className="score">{partnerGrammarAssessTwo}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={3} md={3} className="scoreItem">
                                    <Grid container>
                                        <Typography sx={{ pb: 1 }} className="mobile-main-title">
                                            ASSESSMENT 3
                                        </Typography>
                                        <Grid container>
                                            <Grid item xs={6} className="item you">
                                                <Typography noWrap className="user">
                                                    You
                                                </Typography>
                                                <Typography className="score">{myGrammarAssessThree}</Typography>
                                            </Grid>
                                            <Grid item xs={6} className="item partner">
                                                <Typography noWrap className="user">
                                                    {partnerNameAssessThree}
                                                </Typography>
                                                <Typography className="score">{partnerGrammarAssessThree}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={1} md={1} className="scoreItem ave">
                                    <Grid container>
                                        <Grid item xs={12} className="item avarage">
                                            <Typography noWrap className="user">
                                                Average
                                            </Typography>
                                            <Typography className="score">
                                                {getAverage([
                                                    myGrammarAssessOne,
                                                    myGrammarAssessTwo,
                                                    myGrammarAssessThree,
                                                    partnerGrammarAssessOne,
                                                    partnerGrammarAssessTwo,
                                                    partnerGrammarAssessThree
                                                ])}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container className="row main">
                                <Grid item xs={2} md={2} className="title">
                                    <Typography sx={{ pb: 2 }}>PRONUNCIATION</Typography>
                                </Grid>
                                <Grid item xs={3} md={3} className="scoreItem">
                                    <Grid container>
                                        <Typography sx={{ pb: 1 }} className="mobile-main-title">
                                            ASSESSMENT 1
                                        </Typography>
                                        <Grid item xs={6} className="item you">
                                            <Typography noWrap className="user">
                                                You
                                            </Typography>
                                            <Typography className="score">{myPronunciationAssessOne}</Typography>
                                        </Grid>
                                        <Grid item xs={6} className="item partner">
                                            <Typography noWrap className="user">
                                                {partnerNameAssessOne}
                                            </Typography>
                                            <Typography className="score">{partnerPronunciationAssessOne}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={3} md={3} className="scoreItem">
                                    <Grid container>
                                        <Typography sx={{ pb: 1 }} className="mobile-main-title">
                                            ASSESSMENT 2
                                        </Typography>
                                        <Grid item xs={6} className="item you">
                                            <Typography noWrap className="user">
                                                You
                                            </Typography>
                                            <Typography className="score">{myPronunciationAssessTwo}</Typography>
                                        </Grid>
                                        <Grid item xs={6} className="item partner">
                                            <Typography noWrap className="user">
                                                {partnerNameAssessTwo}
                                            </Typography>
                                            <Typography className="score">{partnerPronunciationAssessTwo}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={3} md={3} className="scoreItem">
                                    <Grid container>
                                        <Typography sx={{ pb: 1 }} className="mobile-main-title">
                                            ASSESSMENT 3
                                        </Typography>
                                        <Grid container>
                                            <Grid item xs={6} className="item you">
                                                <Typography noWrap className="user">
                                                    You
                                                </Typography>
                                                <Typography className="score">{myPronunciationAssessThree}</Typography>
                                            </Grid>
                                            <Grid item xs={6} className="item partner">
                                                <Typography noWrap className="user">
                                                    {partnerNameAssessThree}
                                                </Typography>
                                                <Typography className="score">{partnerPronunciationAssessThree}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={1} md={1} className="scoreItem ave">
                                    <Grid container>
                                        <Grid item xs={12} className="item avarage">
                                            <Typography noWrap className="user">
                                                Average
                                            </Typography>
                                            <Typography className="score">
                                                {getAverage([
                                                    myPronunciationAssessOne,
                                                    myPronunciationAssessTwo,
                                                    myPronunciationAssessThree,
                                                    partnerPronunciationAssessOne,
                                                    partnerPronunciationAssessTwo,
                                                    partnerPronunciationAssessThree
                                                ])}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container className="row main">
                                <Grid item xs={2} md={2} className="title">
                                    <Typography sx={{ pb: 2 }}>FLUENCY</Typography>
                                </Grid>
                                <Grid item xs={3} md={3} className="scoreItem">
                                    <Grid container>
                                        <Typography sx={{ pb: 1 }} className="mobile-main-title">
                                            ASSESSMENT 1
                                        </Typography>
                                        <Grid item xs={6} className="item you">
                                            <Typography noWrap className="user">
                                                You
                                            </Typography>
                                            <Typography className="score">{myFluencyAssessOne}</Typography>
                                        </Grid>
                                        <Grid item xs={6} className="item partner">
                                            <Typography noWrap className="user">
                                                {partnerNameAssessOne}
                                            </Typography>
                                            <Typography className="score">{partnerFluencyAssessOne}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={3} md={3} className="scoreItem">
                                    <Grid container>
                                        <Typography sx={{ pb: 1 }} className="mobile-main-title">
                                            ASSESSMENT 2
                                        </Typography>
                                        <Grid item xs={6} className="item you">
                                            <Typography noWrap className="user">
                                                You
                                            </Typography>
                                            <Typography className="score">{myFluencyAssessTwo}</Typography>
                                        </Grid>
                                        <Grid item xs={6} className="item partner">
                                            <Typography noWrap className="user">
                                                {partnerNameAssessTwo}
                                            </Typography>
                                            <Typography className="score">{partnerFluencyAssessTwo}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={3} md={3} className="scoreItem">
                                    <Grid container>
                                        <Typography sx={{ pb: 1 }} className="mobile-main-title">
                                            ASSESSMENT 3
                                        </Typography>
                                        <Grid container>
                                            <Grid item xs={6} className="item you">
                                                <Typography noWrap className="user">
                                                    You
                                                </Typography>
                                                <Typography className="score">{myFluencyAssessThree}</Typography>
                                            </Grid>
                                            <Grid item xs={6} className="item partner">
                                                <Typography noWrap className="user">
                                                    {partnerNameAssessThree}
                                                </Typography>
                                                <Typography className="score">{partnerFluencyAssessThree}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={1} md={1} className="scoreItem ave">
                                    <Grid container>
                                        <Grid item xs={12} className="item avarage">
                                            <Typography noWrap className="user">
                                                Average
                                            </Typography>
                                            <Typography className="score">
                                                {getAverage([
                                                    myFluencyAssessOne,
                                                    myFluencyAssessTwo,
                                                    myFluencyAssessThree,
                                                    partnerFluencyAssessOne,
                                                    partnerFluencyAssessTwo,
                                                    partnerFluencyAssessThree
                                                ])}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container className="row final-marks">
                            <Grid item xs={6} md={6} className="scoreItem left">
                                <Grid container>
                                    <Grid item xs={12} className="item avarage">
                                        <div className="final-score">
                                            <Typography noWrap className="user">
                                                END OF LESSON QUIZ
                                            </Typography>
                                            <Typography className="score">{parseFloat(userEndOfLessonScore).toFixed(2)}</Typography>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6} md={6} className="scoreItem right">
                                <Grid container>
                                    <Grid item xs={12} className="item avarage">
                                        <div className="final-score">
                                            <Typography className="user">END OF LESSON QUIZ</Typography>
                                            <Typography className="score">{parseFloat(userEndOfLessonScore).toFixed(2)}</Typography>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
        </>
    );
}
export default Speakingone;
