import { call, put } from 'redux-saga/effects';
import placementService from 'services/placement.service';
import { setQuestions } from 'store/reducers/placementReducer';

export default function* handleGetQuestionsList({ level, excluded }) {
    try {
        const response = yield call(placementService.getQuestionList, level, excluded);
        const questions = response.data.map((question) => {
            const obj = {
                ...question,
                field_answer_one: { key: '1', value: question.field_answer_one },
                field_answer_two: { key: '2', value: question.field_answer_two },
                field_answer_three: { key: '3', value: question.field_answer_three },
                field_answer_four: { key: '4', value: question.field_answer_four }
            };
            return obj;
        });
        yield put(setQuestions(questions, level));
    } catch (error) {
        console.error(error);
    }
}
