import PropTypes from 'prop-types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

// material-ui
import { makeStyles } from '@material-ui/styles';
import { Box, Button, IconButton, Stack } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

// project imports
import LogoSection from '../LogoSection';
import MobileSection from './MobileSection';

// assets
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';

import LoginIcon from '@material-ui/icons/Login';
import PersonAddAltIcon from '@material-ui/icons/PersonAddAlt';
import PersonIcon from '@material-ui/icons/Person';
import LogoutIcon from '@material-ui/icons/Logout';

// style constant
const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1
    },
    headerAvatar: {
        ...theme.typography.commonAvatar,
        ...theme.typography.mediumAvatar,
        transition: 'all .2s ease-in-out',
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
        color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
        '&:hover': {
            background: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
            color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.light
        }
    },
    boxContainer: {
        width: '228px',
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            width: 'auto'
        }
    }
}));

// ===========================|| MAIN NAVBAR / HEADER ||=========================== //

const Header = ({ handleLeftDrawerToggle }) => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const isLoggedIn = useSelector((state) => state.account.isLoggedIn);
    return (
        <>
            <div className={classes.boxContainer}>
                <IconButton onClick={handleLeftDrawerToggle} edge="start" color="inherit" aria-label="menu" sx={{ margin: '15px 0' }}>
                    <MenuIcon />
                </IconButton>

                <Box component="span" sx={{ display: { xs: 'block', md: 'block' } }}>
                    <LogoSection />
                </Box>
            </div>

            <Box
                sx={{
                    display: 'flex',
                    flexGrow: 1,
                    justifyContent: 'space-between',
                    alignContent: 'space-between',
                    bgcolor: 'background.paper'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexGrow: 1,
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mx: 1,
                        bgcolor: 'background.paper'
                    }}
                />

                <Stack direction="row" sx={{ display: { xs: 'none', sm: 'block' } }} spacing={2}>
                    {!isLoggedIn === true && (
                        <Button variant="contained" color="primary" component={RouterLink} to="/login">
                            Log in
                        </Button>
                    )}

                    {!isLoggedIn === true && (
                        <Button variant="contained" color="primary" component={RouterLink} to="/register">
                            Sign up
                        </Button>
                    )}

                    {isLoggedIn === true && (
                        <>
                            <Button variant="contained" color="primary" component={RouterLink} to="/profile">
                                Profile
                            </Button>
                            <Button variant="contained" color="primary" onClick={() => dispatch({ type: 'LOGOUT' })}>
                                Log out
                            </Button>
                        </>
                    )}
                </Stack>
                <Stack direction="row" sx={{ display: { xs: 'block', sm: 'none' } }} spacing={2}>
                    {!isLoggedIn === true && (
                        // <Button variant="contained" color="primary" component={RouterLink} to="/login">
                        //     Log in
                        // </Button>
                        <IconButton component={RouterLink} to="/login">
                            <LoginIcon sx={{ color: '#376fb7' }} />
                        </IconButton>
                    )}

                    {!isLoggedIn === true && (
                        // <Button variant="contained" color="primary" component={RouterLink} to="/register">
                        //     Sign up
                        // </Button>
                        <IconButton component={RouterLink} to="/register" sx={{ marginLeft: '0 !important' }}>
                            <PersonAddAltIcon sx={{ color: '#376fb7', marginLeft: '0' }} />
                        </IconButton>
                    )}

                    {isLoggedIn === true && (
                        <>
                            {/* <Button variant="contained" color="primary" component={RouterLink} to="/profile">
                                    Profile
                                </Button> */}
                            <IconButton component={RouterLink} to="/profile">
                                <PersonIcon sx={{ color: '#376fb7', marginLeft: '0' }} />
                            </IconButton>
                            {/* <Button variant="contained" color="primary" onClick={() => dispatch({ type: 'LOGOUT' })}>
                                    Log out
                                </Button> */}
                            <IconButton onClick={() => dispatch({ type: 'LOGOUT' })} sx={{ marginLeft: '0 !important' }}>
                                <LogoutIcon sx={{ color: '#376fb7', marginLeft: '0' }} />
                            </IconButton>
                        </>
                    )}
                </Stack>
            </Box>

            {/* mobile header */}
            {/* <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <MobileSection />
            </Box> */}
        </>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
