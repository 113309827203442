import { GET_USER, SET_USER, SET_CLASS_SELECTED, SET_PAYMENT_SUCCESS } from 'store/actions';

const initialState = {
    userDetails: '',
    classSelected: false,
    paymentSuccess: false
};

export const getUser = (id) => ({
    type: GET_USER,
    id
});

export const setUser = (userDetails) => ({
    type: SET_USER,
    payload: {
        userDetails
    }
});

export const setClassSelected = (classSelected) => ({
    type: SET_CLASS_SELECTED,
    payload: {
        classSelected
    }
});

export const setPaymentSuccess = (paymentSuccess) => ({
    type: SET_PAYMENT_SUCCESS,
    payload: {
        paymentSuccess
    }
});

const userReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_USER:
        case SET_CLASS_SELECTED:
            return { ...state, ...payload };
        case SET_PAYMENT_SUCCESS:
            return { ...state, ...payload };
        default:
            return state;
    }
};

export default userReducer;
