import axios from 'axios';
import API_BASE_URL from './config';

const countryService = {
    getCountryList() {
        return axios.request({
            method: 'GET',
            url: `${API_BASE_URL}api/country_list?_format=json`
        });
    }
};

export default countryService;
