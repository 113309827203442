// action - account reducer
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const ACCOUNT_INITIALIZE = 'ACCOUNT_INITIALIZE';
export const FIREBASE_STATE_CHANGED = 'FIREBASE_STATE_CHANGED';
export const GET_USER = 'GET_USER';
export const SET_USER = 'SET_USER';
export const SET_CLASS_SELECTED = 'SET_CLASS_SELECTED';
export const SET_PAYMENT_SUCCESS = 'SET_PAYMENT_SUCCESS';

export const SET_COURSES = 'SET_COURSES';
export const GET_COURSES = 'GET_COURSES';
export const GET_COURSE_LIST = 'GET_COURSE_LIST';
export const SET_COURSE_LIST = 'SET_COURSE_LIST';

// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const MENU_TYPE = '@customization/MENU_TYPE';
export const PRESET_COLORS = '@customization/PRESET_COLORS';
export const THEME_LOCALE = '@customization/THEME_LOCALE';
export const THEME_RTL = '@customization/THEME_RTL';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';
export const SET_OUTLINED_FILLED = '@customization/SET_OUTLINED_FILLED';

// action - snackbar
export const SNACKBAR_OPEN = '@snackbar/SNACKBAR_OPEN';

// action - cart

export const ADD_PRODUCTS = '@cart/ADD_PRODUCTS';
export const REMOVE_PRODUCT = '@cart/REMOVE_PRODUCT';
export const UPDATE_QUANTITY = '@cart/UPDATE_QUANTITY';
export const NEXT_STEP = '@cart/NEXT_STEP';
export const BACK_STEP = '@cart/BACK_STEP';
export const SET_STEP = '@cart/SET_STEP';
export const SET_BILLING_ADDRESS = '@cart/SET_BILLING_ADDRESS';
export const SET_DISCOUNT = '@cart/SET_DISCOUNT';
export const SET_SHIPPING_CHARGE = '@cart/SET_SHIPPING_CHARGE';
export const SET_PAYMENT_METHOD = '@cart/SET_PAYMENT_METHOD';
export const SET_PAYMENT_CARD = '@cart/SET_PAYMENT_CARD';
export const RESET_CART = '@cart/RESET_CART';

// actions - placement test
export const NEXT_STAGE = 'NEXT_STAGE';
export const SET_STAGE_STATUS = 'SET_STAGE_STATUS';
export const GET_QUESTIONS_LIST = 'GET_QUESTIONS_LIST';
export const SET_QUESTIONS_LIST = 'SET_QUESTIONS_LIST';
export const SET_ACTIVE_QUESTION = 'SET_ACTIVE_QUESTION';
export const GET_AVTIVE_QUESTION = 'GET_AVTIVE_QUESTION';
export const SET_STAGE = 'SET_STAGE';
export const SET_WELCOME_PAGE = 'SET_WELCOME_PAGE';
export const SET_ANSWER = 'SET_ANSWER';
export const SET_SCORE = 'SET_SCORE';
export const SET_GROUP = 'SET_GROUP';

// landing
export const SET_LANDING_CONTENT = 'SET_LANDING_CONTENT';
export const GET_LANDING_CONTENT = 'GET_LANDING_CONTENT';

// lesson
export const SET_LESSON_ACTIVITIES = 'SET_LESSON_ACTIVITIES';
export const GET_LESSON_ACTIVITIES = 'GET_LESSON_ACTIVITIES';
export const SET_LESSON_STAGE = 'SET_LESSON_STAGE';
export const SET_PEER_STAGE = 'SET_LESSON_STAGE';
export const GET_LESSON_STAGE = 'GET_LESSON_STAGE';
export const SET_USERNAME = 'SET_USERNAME';
export const GET_USERNAME = 'GET_USERNAME';
export const SET_PAIRING_DATA = 'SET_PAIRING_DATA';
// export const SET_LESSON_PAIRING = 'SET_LESSON_PAIRING';

// country
export const GET_COUNTRY_LIST = 'GET_COUNTRY_LIST';
export const SET_COUNTRY_LIST = 'SET_COUNTRY_LIST';

//
export const GET_ARTICLE_CONTENT = 'GET_ARTICLE_CONTENT';
export const SET_ARTICLE_CONTENT = 'SET_ARTICLE_CONTENT';
