import React from 'react';

// material-ui
import { makeStyles, useTheme } from '@material-ui/styles';

// ===========================|| LOGO SVG ||=========================== //
import logo from 'assets/images/landing/Logo.png';

const useStyles = makeStyles((theme) => ({
    imageClass: {
        maxWidth: '100%',
        height: '50px',
        [theme.breakpoints.down('sm')]: {
            // marginLeft: '40px'
        }
    }
}));

const Logo = () => {
    const theme = useTheme();
    const classes = useStyles();
    return <img src={logo} alt="English Focus" className={classes.imageClass} />;
};

export default Logo;
