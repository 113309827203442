import React from 'react';
import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase, Typography } from '@material-ui/core';

// project imports
import config from 'config';
import Logo from 'ui-component/Logo';

// ===========================|| MAIN LOGO ||=========================== //

const LogoSection = () => (
    <>
        <ButtonBase sx={{ mt: '10px' }} disableRipple component={Link} to={config.defaultPath}>
            <Logo />
            <Typography
                variant="h3"
                sx={{
                    ml: 1,
                    fontSize: { xs: '16px', md: '20px' },
                    fontWeight: '400'
                }}
            >
                English Focus
            </Typography>
        </ButtonBase>
    </>
);

export default LogoSection;
