import { call, put } from 'redux-saga/effects';
import countryService from 'services/country.service';
import { setCountryList } from 'store/reducers/countryReducer';

export default function* handleGetCountries() {
    try {
        const response = yield call(countryService.getCountryList);
        yield put(setCountryList(response.data));
    } catch (error) {
        console.log(error);
    }
}
