import { call, put } from 'redux-saga/effects';
import AuthService from 'services/auth.services';
import { setUser } from 'store/reducers/userReducer';

export default function* handleGetUser({ id }) {
    try {
        const response = yield call(AuthService.getUser, id);
        yield put(setUser(response.data.data));
    } catch (error) {
        console.log(error);
    }
}
