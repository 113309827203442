const other = {
    id: 'sample-docs-roadmap',
    type: 'group',
    children: [
        {
            id: 'home',
            title: 'Home',
            type: 'item',
            url: '/',
            // icon: icons.IconBrandChrome,
            caption: '',
            breadcrumbs: false
        },
        {
            id: 'profile',
            title: 'Profile',
            type: 'item',
            url: '/profile',
            // icon: icons.IconBrandChrome,
            caption: '',
            breadcrumbs: false
        },
        {
            id: 'courses',
            title: 'Courses',
            type: 'item',
            url: '/courses',
            breadcrumbs: false
        },
        {
            id: 'clubs',
            title: 'Clubs',
            type: 'item',
            url: '/clubs',
            breadcrumbs: false
        },
        {
            id: 'methods',
            title: 'Methods',
            type: 'item',
            url: '/methods',
            breadcrumbs: false
        },
        {
            id: 'about-english-focus',
            title: 'About English Focus',
            type: 'item',
            url: '/about-english-focus',
            breadcrumbs: false
        }
    ]
};
export default other;
